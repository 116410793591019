export default function(context) {
    const {
        minimumAmount,
        minimumAmountUs,
    } = context;

    const subTotalAmount = $('[data-sub-total]').data('sub-total');
    const $minimumAmount = $('.minimum-amount');
    const $minimumAmountUs = $('.minimum-amount-us');
    const $checkoutButton = $('.gtm-checkout-button');
    const user_role_id = $('.user-group-id').html();

    if(user_role_id == 15087 || user_role_id == 15088) return;

    if (minimumAmount && subTotalAmount) {
        $('.number-custom', $minimumAmount).html(`$${minimumAmount}`);
        if(Number(subTotalAmount) < Number(minimumAmount)){
            $minimumAmount.show();
            $checkoutButton.attr('disabled', true);
        } else {
            $minimumAmount.hide();
            $checkoutButton.attr('disabled', false);
        }
    }

    if (minimumAmountUs && subTotalAmount) {
        $('.number-custom-us', $minimumAmountUs).html(`$${minimumAmountUs}`);
        if(Number(subTotalAmount) < Number(minimumAmountUs)){
            $minimumAmountUs.show();
        } else {
            $minimumAmountUs.hide();
        }
    }
};
