export default function (){
    const footerFax = $('.footer-fax-text')[0].innerHTML;
    const footerEmail = $('.footer-email-text')[0].innerHTML
    const headFax = $('.fax-info')
    const headEmail = $('.email-info')
    var fax = footerFax
    var email = `General: <strong>${footerEmail}</strong>`
    headFax.html(fax);
    headEmail.html(email);
}
