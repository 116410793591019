import 'foundation-sites/js/foundation/foundation';
import 'foundation-sites/js/foundation/foundation.dropdown';
import utils from '@bigcommerce/stencil-utils';
import CheckoutLimit from '../custom/checkout-limit';

export const CartPreviewEvents = {
    close: 'closed.fndtn.dropdown',
    open: 'opened.fndtn.dropdown',
};

export default function (context) {
    const {
        secureBaseUrl, cartId
    } = context;
    const loadingClass = 'is-loading';
    const $cart = $('[data-cart-preview]');
    const $cartDropdown = $('#cart-preview-dropdown');
    const $cartLoading = $('<div class="loadingOverlay"></div>');

    const $body = $('body');

    if (window.ApplePaySession) {
        $cartDropdown.addClass('apple-pay-supported');
    }

    $body.on('cart-total-update', (event, total) => {
        if (total) {
            $('.cart-preview-total').text(`$${total.toFixed(2, 10)}`);
        } else {
            utils.api.cart.getCart({}, (err, response) => {
                if (response) {
                    const currency = response.currency.symbol;
                    // const cartAmount = response.cartAmount;
                    let totalQuantity = 0;
                    let totalPrices = 0;

                    if (response.lineItems && response.lineItems.physicalItems) {
                        const physicalItems = response.lineItems.physicalItems;
                        physicalItems.forEach(lineItem => {
                            const {
                                salePrice,
                                quantity,
                            } = lineItem;

                            totalQuantity += quantity;
                            totalPrices += salePrice * quantity;
                        });
                    }

                    if (totalQuantity > 0) {
                        $cart.addClass('has-quantity');
                        $cart.attr('aria-label', (_, prevValue) => prevValue.replace(/\d+/, totalQuantity));

                        $('.cart-quantity')
                            .text(totalQuantity)
                            .toggleClass('countPill--positive', totalQuantity > 0);
                        if (utils.tools.storage.localStorageAvailable()) {
                            localStorage.setItem('cart-quantity', totalQuantity);
                        }
                    } else {
                        $cart.removeClass('has-quantity');
                    }

                    if (currency && totalPrices) $('.cart-preview-total').text(currency + totalPrices.toFixed(2, 10));
                }
            });
        }
    });

    $body.on('cart-quantity-update', (event, quantity) => {
        $cart.attr('aria-label', (_, prevValue) => prevValue.replace(/\d+/, quantity));

        if (!quantity) {
            $cart.addClass('navUser-item--cart__hidden-s');
        } else {
            $cart.removeClass('navUser-item--cart__hidden-s');
        }

        $('.cart-quantity')
            .text(quantity)
            .toggleClass('countPill--positive', quantity > 0);
        if (utils.tools.storage.localStorageAvailable()) {
            localStorage.setItem('cart-quantity', quantity);
        }
    });

    $body.trigger('cart-total-update');

    $cart.on('click', event => {
        const options = {
            template: 'common/cart-preview',
        };

        // Redirect to full cart page
        //
        // https://developer.mozilla.org/en-US/docs/Browser_detection_using_the_user_agent
        // In summary, we recommend looking for the string 'Mobi' anywhere in the User Agent to detect a mobile device.
        if (/Mobi/i.test(navigator.userAgent)) {
            return event.stopPropagation();
        }

        event.preventDefault();

        $cartDropdown
            .addClass(loadingClass)
            .html($cartLoading);
        $cartLoading
            .show();

        utils.api.cart.getContent(options, (err, response) => {
            $cartDropdown
                .removeClass(loadingClass)
                .html(response);
            $cartLoading
                .hide();

            const totalPrices = $('[data-total-prices]', $(response)).data('totalPrices');
            $('body').trigger('cart-total-update', totalPrices);
            CheckoutLimit(context);
        });
    });

    let quantity = 0;

    if (cartId) {
        // Get existing quantity from localStorage if found
        if (utils.tools.storage.localStorageAvailable()) {
            if (localStorage.getItem('cart-quantity')) {
                quantity = Number(localStorage.getItem('cart-quantity'));
                $body.trigger('cart-quantity-update', quantity);
            }
        }

        // Get updated cart quantity from the Cart API
        const cartQtyPromise = new Promise((resolve, reject) => {
            utils.api.cart.getCartQuantity({ baseUrl: secureBaseUrl, cartId }, (err, qty) => {
                if (err) {
                    // If this appears to be a 404 for the cart ID, set cart quantity to 0
                    if (err === 'Not Found') {
                        resolve(0);
                    } else {
                        reject(err);
                    }
                }
                resolve(qty);
            });
        });

        // If the Cart API gives us a different quantity number, update it
        cartQtyPromise.then(qty => {
            quantity = qty;
            $body.trigger('cart-quantity-update', quantity);
        });
    } else {
        $body.trigger('cart-quantity-update', quantity);
    }
}
