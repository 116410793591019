class GTM {
    constructor() {
        window.dataLayer = window.dataLayer || [];
        window.gtmProducts = window.gtmProducts || [];
        this.currencyCode = $('[data-currency-code]').data('currency-code');

        this.bindEvents();
    }

    /************** Render in Html **************/
    // setGTMProductsData($selector) {
    //     const productsStr = $selector.text();
    //     const products = JSON.parse(productsStr);

    //     if (products && products.length > 0) {
    //         for(const i in products) {
    //             let noExist = true;
    //             for(const j in window.gtmProducts) {
    //                 if (products[i].id === window.gtmProducts[j].id) {
    //                     noExist = false;

    //                     if (!window.gtmProducts[j].category) window.gtmProducts[j].category = products[i].category;
    //                     if (!window.gtmProducts[j].quantity) window.gtmProducts[j].quantity = products[i].quantity;
    //                 }
    //             }

    //             if (noExist) {
    //                 window.gtmProducts.push(products[i]);
    //             }
    //         }
    //     }

    //     console.log('GTM Products: ');
    //     console.log(window.gtmProducts);
    // }

    getGTMProductData(sku, qty = null) {
        const products = [];
        window.gtmProducts.map(product => {
            if (product.id === sku) {
                if (qty === null) {
                    if (product.quantity) delete product.quantity;
                } else {
                    product.quantity = Number(qty);
                    delete product.position;
                }
                products.push(product);
            }
        })

        // console.log('Current GTM Product: ');
        // console.log(products);
        return products;
    }

    getCartGTMProductData() {
        const products = [];
        window.gtmProducts.map(product => {
            if (product.quantity) {
                delete product.position;
                products.push(product);
            }
        })

        // console.log('Current Cart GTM Product: ');
        // console.log(products);
        return products;
    }

    /************** Render in Html **************/
    // addProductImpressionsGTMDataLayer($selector) {
    //     const impressionFieldObjectsStr = $selector.text();
    //     const impressionFieldObjects = JSON.parse(impressionFieldObjectsStr);

    //     window.dataLayer.push({ ecommerce: null });
    //     window.dataLayer.push({
    //         'ecommerce': {
    //             'currencyCode': this.currencyCode,
    //             'impressions': impressionFieldObjects,
    //         }
    //     });

    //     console.log('GTM eCommerce: Product Impressions');
    //     console.log(window.dataLayer);

    //     this.setGTMProductsData($selector);
    // }

    addProductClicksGTMDataLayer(sku, listName, url) {
        const productFieldObjects = this.getGTMProductData(sku);
        if (productFieldObjects.length > 0) {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'actionField': {
                            'list': listName,
                        },
                        'products': productFieldObjects,
                    }
                },
                'eventCallback': function() {
                    document.location = url;
                },
            });

            console.log('GTM eCommerce: productClick');
            console.log(window.dataLayer);
            console.log(JSON.stringify(window.dataLayer));
        }
    }

    addAddToCartGTMDataLayer(sku, qty) {
        const productFieldObjects = this.getGTMProductData(sku, qty);
        if (productFieldObjects.length > 0) {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                'event': 'addToCart',
                'ecommerce': {
                    'currencyCode': this.currencyCode,
                    'add': {
                        'products': productFieldObjects,
                    },
                },
            });

            console.log('GTM eCommerce: addToCart');
            console.log(window.dataLayer);
        }
    }

    addRemoveFromCartGTMDataLayer(sku, qty) {
        const productFieldObjects = this.getGTMProductData(sku, qty);
        if (productFieldObjects.length > 0) {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                'event': 'removeFromCart',
                'ecommerce': {
                    'currencyCode': this.currencyCode,
                    'remove': {
                        'products': productFieldObjects,
                    },
                },
            });

            console.log('GTM eCommerce: removeFromCart');
            console.log(window.dataLayer);
        }
    }

    addCheckoutDataLayer(url) {
        const cartProducts = this.getCartGTMProductData();

        if (cartProducts.length > 0) {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                'event': 'checkout',
                'ecommerce': {
                    'checkout': {
                        'actionField': {
                            'step': 1,
                            'option': 'Customer',
                        },
                        'products': cartProducts,
                    },
                },
                'eventCallback': function() {
                    document.location = url;
                }
            });

            console.log('GTM eCommerce: checkout');
            console.log(url);
            console.log(window.dataLayer);
            console.log(JSON.stringify(window.dataLayer));
            window.location.href = url;
        }
    }

    bindEvents() {
        /******************
         * GTM eCommerce: Product Clicks *
         * *****************/
        $('body').on('click', '.gtm-product-link', event => {
            event.preventDefault();
            const $target = $(event.target).closest('.gtm-product-link');
            const $product = $target.closest('[data-gtm-product-sku]');
            const $list = $product.closest('[data-list-name]');

            const sku = $product.data('gtm-product-sku') || '';
            const listName = $list.data('list-name') || '';
            const url = $target.attr('href') || '';

            this.addProductClicksGTMDataLayer(sku, listName, url);
            window.location.href = url;
        })

        /******************
         * GTM eCommerce: Checkout Button Clicks *
         * *****************/
        $('body').on('click', '.gtm-checkout-button', event => {
            event.preventDefault();
            const $target = $(event.target).closest('.gtm-checkout-button');
            const url = $target.attr('href') || '';
            this.addCheckoutDataLayer(url);
        })
    }
}

export default function() {
    window.CustomGTM = new GTM();
    return CustomGTM;
};
