export default function (customer) {
    if (customer) {
        $('div[data-test-id="product-widget-price"]').show();
        $('div[data-test-id="product-set-widget-price"]').show();

        setTimeout(() => {
            $('div[data-test-id="product-widget-price"]').show();
            $('div[data-test-id="product-set-widget-price"]').show();
        }, 3000);

        setTimeout(() => {
            $('div[data-test-id="product-widget-price"]').show();
            $('div[data-test-id="product-set-widget-price"]').show();
        }, 5000);

        setTimeout(() => {
            $('div[data-test-id="product-widget-price"]').show();
            $('div[data-test-id="product-set-widget-price"]').show();
        }, 10000);
    }
}
