export default function () {
    $('#menu').on('click', () => {
        $('[data-menu]').removeClass('is-open')
        $('.mobileMenu-toggle').removeClass('is-open')
        $('body').removeClass('has-activeNavPages')
        if ($('body').hasClass('has-activeNavPages')) {
            document.documentElement.style.overflow = 'hidden';
        }
        else {
            document.documentElement.style.overflow = 'scroll';
        }
    })
    $('.navUser').on('click', () => {
        $('[data-menu]').removeClass('is-open')
        $('.mobileMenu-toggle').removeClass('is-open')
        $('body').removeClass('has-activeNavPages')
    })
    $('.navPages-list').on('click', (e) => {
        stopFunc(e)
    })
    $('.navPage-subMenu').on('click', (e) => {
        stopFunc(e)
    })
    $('body').on('click', () => {
        if ($('body').hasClass('has-activeNavPages')) {
            document.documentElement.style.overflow = 'hidden';
        }
        else {
            document.documentElement.style.overflow = 'scroll';
        }
    })

}
function stopFunc(e) {
    e.stopPropagation ? e.stopPropagation() : e.cancelBubble = true;
}
