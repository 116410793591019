import urlUtils from '../common/utils/url-utils';
class ShopByOem {
    constructor(categories) {
        // Private properties
        this.oemBrandCategories = [];
        this.oemBrandSelector = 'select[name="oem_brand"]';
        this.oemModelSelector = 'select[name="oem_model"]';

        this.$shopByOemSelector = $('#shop-by-oem');
        this.$oemBrandMenuSelector = $(this.oemBrandSelector, this.$shopByOemSelector);
        this.$oemModelMenuSelector = $(this.oemModelSelector, this.$shopByOemSelector);

        this.selectedBrand = window.sessionStorage.getItem('OEM_BRAND') || '';
        this.selectedModel = window.sessionStorage.getItem('OEM_MODEL') || '';

        this.baseUrl = this.$shopByOemSelector.data('base-url');
        this.linkUrl = this.baseUrl;

        if (categories && categories.length > 0) {
            categories.forEach(category => {
                const {
                    name,
                    children,
                } = category;

                if (name.toLowerCase() === 'shop by oem') {
                    this.oemBrandCategories = children;
                }
            });
        }

        // console.log('【Shop by Oem Menu】');
        // console.log('baseUrl: ', this.baseUrl);
        // console.log('selectedBrand: ', this.selectedBrand);
        // console.log('selectedModel: ', this.selectedModel);
        // console.log('oemBrandCategories: ', this.oemBrandCategories);

        // Observe user events
        this.resetOemModelOptions = this.resetOemModelOptions.bind(this);
        this.setOemFilterSubmitUrl = this.setOemFilterSubmitUrl.bind(this);
        this.onOemBrandChanged = this.onOemBrandChanged.bind(this);

        this.initOemSelectedValue();

        this.bindEvents();
    }

    initOemSelectedValue() {
        this.onOemBrandChanged(true);
    }

    setOemBrandSelectedDisplay() {
        this.setProductOemBrandDisplay();
        if (this.selectedBrand) {
            $('#oem-edit-button').show();
            window.sessionStorage.setItem('OEM_BRAND', this.selectedBrand);
        } else {
            $('#oem-edit-button').hide();
            window.sessionStorage.removeItem('OEM_BRAND');
        }

        if (this.selectedModel) {
            window.sessionStorage.setItem('OEM_MODEL', this.selectedModel);
        } else {
            window.sessionStorage.removeItem('OEM_MODEL');
        }
    }

    setProductOemBrandDisplay() {
        $('.js-oem-option').hide();
        if (this.selectedBrand) {
            const $selector = $(`.js-oem-option[data-brand="${this.selectedBrand}"]`);
            $selector.show();
            $selector.parent().find('.oem-part-number').hide();
        } else {
            $('.oem-part-number').show();
        }
    }

    setOemFilterSubmitUrl(params) {
        this.linkUrl = urlUtils.replaceParams(this.linkUrl, params);
    }

    resetOemModelOptions() {
        const $oemModel = $(this.oemModelSelector);
        $oemModel.empty();
        $oemModel.append('<option value="">Select...</option>');
    }

    renderOemModelOptions(categories) {
        if (categories && categories.length > 0) {
            let defaultSelected = '';
            categories.forEach(category => {
                const {
                    name
                } = category;
                if (name === this.selectedModel) {
                    defaultSelected = ' selected';
                } else {
                    defaultSelected = '';
                }

                $(this.oemModelSelector).append(`
                    <option value="${name}"${defaultSelected}>${name}</option>
                `);
            });

            if (!defaultSelected) {
                this.selectedModel = '';
                this.setOemFilterSubmitUrl({
                    'OEM Model': '',
                });
            }
        } else {
            this.selectedModel = '';
            this.setOemFilterSubmitUrl({
                'OEM Model': '',
            });
        }
    }

    onOemBrandChanged(status) {
        // console.log('+++++++++ Oem Brand Changed +++++++++');
        // console.log('status: ', status);
        // console.log('selectedBrand: ', this.selectedBrand);
        // console.log('selectedModel: ', this.selectedModel);

        this.setOemFilterSubmitUrl({
            'OEM Brand': this.selectedBrand,
            'OEM Model': this.selectedModel,
        });

        if (status) this.setOemBrandSelectedDisplay();
        this.resetOemModelOptions();

        $(this.oemBrandSelector).val(this.selectedBrand);

        if (this.selectedBrand) {
            if (this.oemBrandCategories && this.oemBrandCategories.length > 0) {
                this.oemBrandCategories.forEach(category => {
                    const {
                        name,
                        children,
                    } = category;

                    if (name === this.selectedBrand) {
                        this.renderOemModelOptions(children);
                    }
                });
            }
        }
    }

    bindOemBrandChangeEvent() {
        this.$oemBrandMenuSelector.on('change', event => {
            const $target = $(event.target).closest(this.oemBrandSelector);
            const $selected = $('option:selected', $target);
            const selected = $selected.val();
            this.selectedBrand = selected;
            this.selectedModel = '';

            this.onOemBrandChanged();
        });
    }

    bindOemModelChangeEvent() {
        this.$oemModelMenuSelector.on('change', event => {
            const $target = $(event.target).closest(this.oemModelSelector);
            const $selected = $('option:selected', $target);
            const selected = $selected.val();
            this.selectedModel = selected;

            this.setOemFilterSubmitUrl({
                'OEM Model': selected,
            });
        });
    }

    bindOemSubmitButtonClickEvent() {
        $('.js-oem-submit').on('click', () => {
            if (this.linkUrl) window.location.href = this.linkUrl;
        })
    }

    bindOemClearButtonClickEvent() {
        $('.js-oem-clear').on('click', () => {
            $(this.oemBrandSelector).val('');
            this.resetOemModelOptions();
            this.selectedBrand = '';
            this.selectedModel = '';
            this.linkUrl = this.baseUrl;
            window.sessionStorage.removeItem('OEM_BRAND');
            window.sessionStorage.removeItem('OEM_MODEL');
            $('#oem-edit-button').hide();
        })
    }

    bindEvents() {
        // Clean-up
        this.unbindEvents();

        // DOM events
        this.bindOemBrandChangeEvent();
        this.bindOemModelChangeEvent();
        this.bindOemSubmitButtonClickEvent();
        this.bindOemClearButtonClickEvent();

        $('#home-shop-by-oem').on('change', 'select', event => {
            event.preventDefault();
            const $target = $(event.target).closest('select');
            const selected = $target.val();
            const name = $target.attr('name');
            $(`select[name="${name}"]`, this.$shopByOemSelector).val(selected).trigger('change');
        })

        $('body').on('oem-change', (event, {
            selectedBrand,
            selectedModel,
        }) => {
            this.selectedBrand = selectedBrand;
            this.selectedModel = selectedModel;

            this.onOemBrandChanged(true);
        })
    }

    unbindEvents() {
        // DOM events
    }
}

export default ShopByOem;
