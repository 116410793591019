export default function () {
    const url = 'https://cdn.bundleb2b.net/b3-auto-loader.js';

    const el = document.createElement('script');
    el.setAttribute('src', url);
    document.querySelector('body').append(el);
    window.b3themeConfig = window.b3themeConfig || {};
    window.b3themeConfig.useContainers = {
        'orders.container': '.account',
        'tpa.button.container': '#hiddenhiddenhidden',
    };
    window.b3themeConfig.useText = {
        'order.print.invoice.button': 'Print Confirmation',
        'tpa.button': 'Dealer Application Form',
        'qop.upload.sku.title': 'Quick Order via SKU for Bedford Part Numbers Only',
        'qop.thead.variantSku': 'Bedford SKU',
    };
    window.b3themeConfig.useJavaScript = {
        login: {
            callback(instance) {
                console.log(instance.utils.B3Storage);
                const {
                    // B3UserId,
                    B3RoleId,
                    B3CompanyId,
                } = instance.utils.B3Storage;

                const roleId = B3RoleId.value;
                // const userId = B3UserId.value;
                const companyId = B3CompanyId.value;

                if (roleId === '0' || roleId === '1' || roleId === '2' || (roleId === '3' && companyId)) {
                    $('[data-bundle-link]').show();
                    $('[data-bundle-link]').addClass('show');
                }
            },
            mounted() {
                $('.body').show();
            },
        },
        tpa: {
            beforeMount(instance) {
                instance.init = async () => {
                    const {
                        isShowTpaQuery,
                    } = instance.state

                    const isShowTpa = instance.context.page_type === 'createaccount'
                    const isRegisterFail = instance.checkRegisterFail(instance.context)
                    instance.initTpaBtn()
                    if (!instance.isLogin) await instance.guestInit()
                    else await instance.userInit()

                    if (isShowTpa || instance.isInpage || isRegisterFail) {
                        instance.initMyB3ExtraField()
                        document.querySelector('.page-heading').innerHTML = instance.text['tpa.button']
                        document.querySelector('.page-heading').style.display = 'block'
                    }
                }

                instance.bindGuestEvent = () => {
                    $('body').on('click', '#tpa_submit_btn', event => {
                        event.preventDefault();
                        $('form[data-create-account-form]').trigger('submit');
                    })
                }
            }
        },
        quickorderpad: {
            beforeMount: (instance) => {
                instance.triggerCartNumber = () => {
                    // update cart number
                    const options = {
                        template: {
                            content: 'b3/b3json',
                            totals: 'cart/totals',
                            pageTitle: 'cart/page-title',
                            statusMessages: 'cart/status-messages',
                        },
                    }

                    window.B3Spinner.show()
                    stencilUtils.api.cart.getContent(options, (err, response) => {
                        window.B3Spinner.hide()
                        if (err) return
                        const {
                            cart,
                        } = JSON.parse(response.content) || {}

                        const {
                            items,
                            grand_total: grandTotal,
                        } = cart

                        if (!items) return

                        const cartQuantity = items.reduce((result, item) => {
                            result += item.quantity
                            return result
                        }, 0)

                        $('.cart-preview-total').text(grandTotal.formatted)

                        const $cartQtys = document.querySelectorAll('.cart-quantity')

                        $cartQtys.forEach($cartQty => {
                            $cartQty.innerHTML = cartQuantity
                            if (cartQuantity > 0) {
                                $cartQty.classList.add('countPill--positive')
                            } else {
                                $cartQty.classList.remove('countPill--negative')
                            }
                        })

                        $('.cart-quantity')
                            .text(cartQuantity)
                            .toggleClass('countPill--positive', cartQuantity > 0);

                        if (stencilUtils.tools.storage.localStorageAvailable()) {
                            localStorage.setItem('cart-quantity', cartQuantity)
                        }
                    })
                }

                instance.bindAddToCartSelector = async () => {
                    try {
                        const $table = document.querySelector('#quick_order_pad_table')
                        const skus = $table.querySelectorAll('[data-sku]')
                        const variantSkus = instance.getProductsData(skus)
                        if (!variantSkus) return window.B3Spinner.hide()
                        window.B3Spinner.show()
                        const products = await instance.api.getProductsBySkuQuickByPost({ variantSkus })
                        const elements = document.querySelectorAll('#quick_order_pad_table>tbody>tr:not(.err-qty):not(.err-data):not(.err-sku)')
                        instance.fiterProductsBysku(products, skus)
                        const productsMap = {}
                        products.forEach(product => {
                            const { variantSku } = product
                            productsMap[variantSku.toUpperCase()] = product
                        })

                        const validatedElement = []
                        for (let i = 0; i < elements.length; i += 1) {
                            const $trItem = elements[i]
                            const productId = $trItem.getAttribute('data-element-id')
                            const quantity = $trItem.querySelector('[data-qty]').value
                            const sku = $trItem.querySelector('.form-input').value?.trim().toUpperCase()

                            if (!productId || !quantity) {
                                continue
                            }

                            const currentTrItemInfo = productsMap[sku]
                            const $errMessage = document.querySelector(`[data-element-id='${productId}'] .th-col-message`)
                            if (+currentTrItemInfo.isStock === 1 && (+currentTrItemInfo.stock === 0 || quantity > currentTrItemInfo.stock)) {
                                $errMessage.innerHTML = instance.utils.text('qop.sku.stock', {
                                    hash: {
                                    sku: currentTrItemInfo.variantSku,
                                    },
                                })
                                continue
                            }

                            if (+currentTrItemInfo.purchasingDisabled === 1) {
                                $errMessage.innerHTML = instance.text['qop.sku.purchasingDisabled']
                                continue
                            }

                            if (+currentTrItemInfo.isVisible === 0) {
                                $errMessage.innerHTML = instance.utils.text('qop.sku.invisible', {
                                    hash: {
                                    sku: currentTrItemInfo.variantSku,
                                    },
                                })
                                continue
                            }

                            if (((currentTrItemInfo.modifiers && currentTrItemInfo.modifiers.length) && currentTrItemInfo.modifiers.find(modifier => modifier.required)) || (currentTrItemInfo.categories && !(currentTrItemInfo.categories.length))) {
                                $errMessage.innerHTML = instance.utils.text('qop.sku.cannotAdd', {
                                    hash: {
                                    sku: currentTrItemInfo.variantSku,
                                    },
                                })
                                continue
                            }

                            if (+currentTrItemInfo.maxQuantity !== 0 && currentTrItemInfo.maxQuantity < quantity) {
                                $errMessage.innerHTML = instance.utils.text('qop.sku.maximum', {
                                    hash: {
                                    sku: currentTrItemInfo.variantSku,
                                    maxQuantity: currentTrItemInfo.maxQuantity,
                                    },
                                })
                                continue
                            }

                            if (+currentTrItemInfo.minQuantity !== 0 && currentTrItemInfo.minQuantity > quantity) {
                                $errMessage.innerHTML = instance.utils.text('qop.sku.minimum', {
                                    hash: {
                                    sku: currentTrItemInfo.variantSku,
                                    minQuantity: currentTrItemInfo.minQuantity,
                                    },
                                })
                                continue
                            }

                            validatedElement.push($trItem)
                        }

                        const { isEnabled } = await instance.api.getAdvQtyState()
                        if (isEnabled === '1') {
                            const qtyProducts = await instance.api.getAdvQtyBySkusNew({ variantSkus })
                            instance.filterQty(qtyProducts.productQuantityList, skus, products)
                        } else {
                            instance.filterQty([], skus, products)
                        }
                        if (elements.length === 0) return window.B3Spinner.hide()
                        instance.addToCartCotent(validatedElement)
                    } catch {
                        window.B3Spinner.hide()
                    } finally {
                        window.B3Spinner.hide()
                    }
                }

                instance.bindSingleSearch = async ($td) => {
                    const $input = $td.querySelector('[data-sku]')
                    const $results = $td.querySelector('.sku-search-results')
                    const setNotFound = () => {
                        $results.innerHTML = (`<div style="margin-bottom:1.5rem;">${instance.text['shopping.list.products.notFound']}</div>`)
                    }
                    const searchQuery = $input.value
                    if (searchQuery.length >= 2) {
                        window.B3Spinner.show()
                        instance.handleInputSearch(() => {
                            if (searchQuery.length < 2) return null
                            $results.innerHTML = ''
                            instance.clearPagination($results.parentNode.querySelector('.more-results'))
                            instance.stencilUtils.api.search.search(`"${searchQuery}"`, {
                                template: 'b3/b3json',
                            }, async (err, response) => {
                                var newres=response.split(/\r?\n/);
                                newres=newres[0];
                                window.B3Spinner.hide()
                                if (err) return setNotFound()
                                const {
                                    product_results: {
                                        products = [],
                                    },
                                } = JSON.parse(newres)
                                const allProductIds = []
                                products.forEach(({
                                    id,
                                }) => allProductIds.push(id))
                                const { list } = await instance.api.getInventory({
                                    products: allProductIds.map(productId => ({
                                        productId,
                                    })),
                                })

                                const productIds = list.filter(({ purchasingDisabled, modifiers }) => (!purchasingDisabled && !modifiers.length)).map(({ productId }) => productId)
                                if (!productIds.length) return setNotFound()

                                const limit = 3
                                const totalPages = Math.ceil(productIds.length / limit)
                                const currentPage = 1

                                const searchProduct = productId => new Promise(resolve => {
                                    const resultContainer = instance.tpls.searchResultContainerTemplate({
                                        productId,
                                    })

                                    $results.insertAdjacentHTML('beforeend', resultContainer)

                                    instance.stencilUtils.api.product.getById(productId, {
                                        template: 'b3/b3json',
                                    }, (error, res) => {
                                        const $productId = $results.querySelector(`.product-${productId}`)
                                        $productId.classList.remove('loading-span')
                                        let optionsHTML = ''
                                        if (error) return
                                        const {
                                            product,
                                        } = JSON.parse(res)

                                        const options = product.options.map(option => {
                                            const values = option.values ? option.values.map(value => ({
                                            ...value,
                                            checked: false,
                                            selected: false,
                                            })) : []
                                            return {
                                            ...option,
                                            checked: false,
                                            values,
                                            }
                                        })
                                        optionsHTML = instance.renderOptions(options)

                                        const resultHtml = instance.tpls.searchResultItemTemplate({
                                            showCheckBox: false,
                                            showSelect: true,
                                            product: {
                                            ...product,
                                            ...instance.utils.getCorrectProductImage(product, 'main_image'),
                                            optionsHTML,
                                            },

                                        })
                                        $productId.innerHTML = resultHtml
                                        instance.bindSelectProduct()
                                        resolve()
                                    })
                                })

                                const uuid = instance.guid()
                                $results.parentNode.querySelector('.more-results').setAttribute('id', `more-results-${uuid}`)
                                const paginationClass = `#more-results-${uuid}`
                                const showProduct = page => {
                                    $results.innerHTML = ''
                                    const $active = $td.querySelector(`${paginationClass} .pagination-item--current`)
                                    const $pageItems = $td.querySelectorAll(`${paginationClass} .pagination-item`)
                                    const $prev = $td.querySelector(`${paginationClass} .pagination-item--previous`)
                                    const $next = $td.querySelector(`${paginationClass} .pagination-item--next`)
                                    $active && $active.classList.remove('pagination-item--current')
                                    $pageItems.length && $pageItems[page].classList.add('pagination-item--current')
                                    $prev && ($prev.setAttribute('data-page', +page - 1), +page === 1 ? $prev.classList.add('disabled') : $prev.classList.remove('disabled'))
                                    $next && ($next.setAttribute('data-page', +page + 1), +page === totalPages ? $next.classList.add('disabled') : $next.classList.remove('disabled'))
                                    productIds
                                        .filter((id, i) => i >= (page - 1) * limit && i < page * limit)
                                        .map(item => searchProduct(item))

                                    instance.setPagination($results)
                                }

                                showProduct(1)

                                window.B3Paginator.init({
                                    container: paginationClass,
                                    currentPage,
                                    totalPages,
                                    onPageChange: showProduct,
                                })
                            })
                        })
                    } else if (searchQuery.length === 0) {
                        $results.innerHTML = ''
                    }
                }
            }
        },
    };
}
