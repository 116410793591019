export default function (){
    const $navPage = $('#navPages-64')
    $('#oem-edit-button').on('click', () => {
        if($navPage.hasClass('is-open') || $navPage.hasClass('showNode')){
            $navPage.removeClass('is-open')
            $navPage.removeClass('showNode')
        }
        if(!$navPage.hasClass('is-open')){
            $('.mobileMenu-toggle').trigger('click')
            setTimeout(() => {
                if($('.custom-navPages').hasClass('is-open')){
                $('[data-collapsible="navPages-64"]').trigger('click')
            }
            }, '200');
        }
    })
}
