import utils from '@bigcommerce/stencil-utils';
import swal from '../global/sweet-alert';
import modalFactory from '../global/modal';

const batchAddToCart = {
    setConfig: (secureBaseUrl, cartId) => {
        batchAddToCart.secureBaseUrl = secureBaseUrl;
        batchAddToCart.cartId = cartId;
    },

    listenQuantityChange: (event) => {
        event.preventDefault();
        const $target = $(event.currentTarget);
        const $currentCard = $target.closest('.listItem');
        const $input = $('.form-input--incrementTotal', $currentCard);

        let qty = parseInt($input.val(), 10);

        // If action is incrementing
        if ($target.data('action') === 'inc') {
            qty++;
        } else if (qty > 0) {
            qty--;
        }

        $input.val(qty);
    },

    getCartContent(cartItemId, onComplete) {
        const options = {
            template: 'cart/preview',
            params: {
                suggest: cartItemId,
            },
            config: {
                cart: {
                    suggestions: {
                        limit: 4,
                    },
                },
            },
        };

        utils.api.cart.getContent(options, onComplete);
    },

    addProductsToCart: (event) => {
        const $addToCartBtn = $(event.target).closest('.js-table-products-ATC');
        const originalBtnVal = $addToCartBtn.find('.text').text();
        const waitMessage = $addToCartBtn.data('waitMessage');
        $addToCartBtn
            .prop('disabled', true)
            .find('.text').text(waitMessage);

        const $qtys = $('.productList .form-input--incrementTotal');
        batchAddToCart.addSingleProductToCart($qtys.toArray(), $addToCartBtn, originalBtnVal);
    },

    addSingleProductToCart: (selectors, $addToCartBtn, originalBtnVal) => {
        if (selectors && selectors.length > 0) {
            const $selector = $(selectors[0]);
            const $product = $selector.closest('.listItem');
            const productQty = Number($selector.val());

            if (Number.isInteger(productQty) && productQty > 0) {
                // Do not do AJAX if browser doesn't support FormData
                if (window.FormData === undefined) {
                    return;
                }

                // Add item to cart
                const action = 'add';
                const productId = $product.data('product-id');
                const sku = $product.data('gtm-product-sku');
                const qty = $product.find('input[name="qty[]"]').val();

                const formData = new FormData();
                formData.append('action', action);
                formData.append('product_id', productId);
                formData.append('qty[]', qty);

                utils.api.cart.itemAdd(formData, (err, response) => {
                    const errorMessage = err || response.data.error;
                    // Guard statement
                    if (errorMessage) {
                        swal.fire({
                            text: errorMessage,
                            icon: 'error',
                            timer: '2000',
                            showConfirmButton: false,
                        });
                    } else {
                        window.CustomGTM.addAddToCartGTMDataLayer(sku, qty);

                        const previewModal = modalFactory('#previewModal')[0];
                        const newSelector = selectors.filter((item, index) => index !== 0);
                        if (newSelector && newSelector.length > 0) {
                            batchAddToCart.addSingleProductToCart(newSelector, $addToCartBtn, originalBtnVal);
                            batchAddToCart.updateCartContent(previewModal, response.data.cart_item.id);
                        } else {
                            $addToCartBtn
                                .prop('disabled', false)
                                .find('.text').text(originalBtnVal);
                            $('.productList .form-input--incrementTotal').val(0);
                            $('.js-plp-subtotal').html('$0.00');
                            $('[data-cart-preview]').eq(0).trigger('click');
                            document.body.scrollTop = document.documentElement.scrollTop = 0;
                        }
                    }
                });
            } else {
                const newSelector = selectors.filter((item, index) => index !== 0);
                if (newSelector && newSelector.length > 0) {
                    batchAddToCart.addSingleProductToCart(newSelector, $addToCartBtn, originalBtnVal);
                } else {
                    $addToCartBtn
                        .prop('disabled', false)
                        .find('.text').text(originalBtnVal);
                    $('.productList .form-input--incrementTotal').val(0);
                    $('.js-plp-subtotal').html('$0.00');
                    $('[data-cart-preview]').eq(0).trigger('click');
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }
            }
        }
    },

    updateCartContent: (modal, cartItemId, onComplete) => {
        batchAddToCart.getCartContent(cartItemId, (err, response) => {
            if (err) {
                return;
            }

            modal.updateContent(response);

            // Update cart counter
            const $body = $('body');
            // const $cartQuantity = $('[data-cart-quantity]', modal.$content);
            const $cartCounter = $('.navUser-action .cart-count');
            // const quantity = $cartQuantity.data('cartQuantity') || 0;
            const totalPrices = $('[data-total-prices]', modal.$content).data('total-prices');
            const $promotionBanner = $('[data-promotion-banner]');
            const $backToShopppingBtn = $('.previewCartCheckout > [data-reveal-close]');
            const $modalCloseBtn = $('#previewModal > .modal-close');
            const bannerUpdateHandler = () => {
                const $productContainer = $('#main-content > .container');

                $productContainer.append('<div class="loadingOverlay pdp-update"></div>');
                $('.loadingOverlay.pdp-update', $productContainer).show();
                window.location.reload();
            };

            $cartCounter.addClass('cart-count--positive');
            // $body.trigger('cart-quantity-update', quantity);
            $body.trigger('cart-total-update', totalPrices);

            if (onComplete) {
                onComplete(response);
            }

            if ($promotionBanner.length && $backToShopppingBtn.length) {
                $backToShopppingBtn.on('click', bannerUpdateHandler);
                $modalCloseBtn.on('click', bannerUpdateHandler);
            }
        });
    },
}

export default function (secureBaseUrl, cartId) {
    const $body = $('body');

    batchAddToCart.setConfig(secureBaseUrl, cartId);
    $body.on('click', '[data-card-quantity-change] button', batchAddToCart.listenQuantityChange);
    $body.on('click', '.js-table-products-ATC', batchAddToCart.addProductsToCart);


    // Prevent triggering quantity change when pressing enter
    $body.on('keypress', '.form-input--incrementTotal', event => {
        // If the browser supports event.which, then use event.which, otherwise use event.keyCode
        const x = event.which || event.keyCode;
        if (x === 13) {
            // Prevent default
            event.preventDefault();
        }
    });
}
