export default function () {
    const tabList = $('.featured-tabs .tab')
    $('body').on('click', '.middle-tab', () => {
        if(window.innerWidth < 551){
            $('.middle-tab')[0].style.position = 'relative'
            $('.middle-tab')[0].style.left = '-100px'
            if(tabList){
                for(let i = 0; i < tabList.length; i++){
                    tabList[i].style.position = 'relative'
                    tabList[i].style.left = '-120px'
                }
            }
        }
        
    })
    $('body').on('click', '.other-tab', () => {
        if(tabList){
            for(let i = 0; i < tabList.length; i++){
                tabList[i].style.position = 'unset'
            }
        }
    })
}
