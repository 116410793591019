import swal from '../global/sweet-alert';

export default function ($selector){
    $('body').on('click',`${$selector}`,(event)=>{
        swal.fire({
            title: 'You are leaving the store',
            text: 'Please press Continue to go to http://www.bedfordprecisionreferences.com/Html_Refs/BedfordQuickRef_List1.html',
            confirmButtonText:'Continue',
            cancelButtonText:'Cancel',
            showCancelButton: true,
            customClass:{
                header:'custom-popup-header',
                content:'custom-popup-content',
                actions:'custom-popup-actions',
                container:'custom-popup-container',
                confirmButton: 'button',
                cancelButton: 'button',
            }
        }).then((result) => {
            if (result.value) {
                window.open('http://www.bedfordprecisionreferences.com/Html_Refs/BedfordQuickRef_List1.html')
            }
        });
        event.preventDefault();
    })
}
