import Url from 'url';

const urlUtils = {
    getUrl: () => `${window.location.pathname}${window.location.search}`,

    goToUrl: (url) => {
        window.history.pushState({}, document.title, url);
        $(window).trigger('statechange');
    },

    replaceParams: (url, params) => {
        const parsed = Url.parse(url, true);
        let param;

        // Let the formatter use the query object to build the new url
        parsed.search = null;

        for (param in params) {
            if (params.hasOwnProperty(param)) {
                if (params[param]) {
                    parsed.query[param] = params[param];
                } else if (parsed.query[param]) {
                    delete parsed.query[param]
                }
            }
        }

        return Url.format(parsed);
    },

    buildQueryString: (queryData) => {
        let out = '';
        let key;
        for (key in queryData) {
            if (queryData.hasOwnProperty(key)) {
                if (Array.isArray(queryData[key])) {
                    let ndx;

                    for (ndx in queryData[key]) {
                        if (queryData[key].hasOwnProperty(ndx)) {
                            out += `&${key}=${queryData[key][ndx]}`;
                        }
                    }
                } else {
                    out += `&${key}=${queryData[key]}`;
                }
            }
        }

        return out.substring(1);
    },

    parseQueryParams: (queryData) => {
        const params = {};

        for (let i = 0; i < queryData.length; i++) {
            const temp = queryData[i].split('=');

            if (temp[0] in params) {
                if (Array.isArray(params[temp[0]])) {
                    params[temp[0]].push(temp[1]);
                } else {
                    params[temp[0]] = [params[temp[0]], temp[1]];
                }
            } else {
                params[temp[0]] = temp[1];
            }
        }

        return params;
    },
};

export default urlUtils;
