import batchAddToCart from './add-to-cart-batch';
import headerInfo from './header-info';
import oemEditButton from './oem-edit-button';
import activeTab from './active-tab';
import menuClose from './menu-close';
import productReference from './product-reference';
import WindowChange from './window-change';
import RestrictToLogin from './restrict-to-login';

export default function (context) {
    batchAddToCart(context.secureBaseUrl, context.cartId);
    headerInfo();
    oemEditButton();
    activeTab();
    menuClose();
    productReference('[custom-product-references]');
    WindowChange();
    RestrictToLogin(context.customer);
}
